import React from "react";
import { Warning } from "components/Warning";
import { useAgeWarningState } from "hooks/useAgeWarningState";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

const Icon = () => {
    return (
        <div className="w-20 h-20 text-[length:40px] flex justify-center items-center mx-auto text-white bg-pink rounded-full">
            !
        </div>
    );
};

export const AgeWarning = () => {
    const { isOpen, onConfirm } = useAgeWarningState();
    const { t } = useTranslation();
    return (
        <Warning
            open={isOpen}
            title={t("modal.age_title")}
            onConfirm={onConfirm}
            onCancel={() => window.history.back()}
            confirm={t("modal.age_confirm")}
            cancel={t("modal.age_deny")}
            icon={<Icon />}
            description={
                <Trans i18nKey="modal.warning_content">
                    <p>
                        本遊戲為限制級作品。
                        <br />
                        您必須年滿18歲或達到您所在地區法定成年之年齡，才可以瀏覽本網站。
                    </p>
                    <p className="mt-4">如果您有任何疑慮，請立即關閉。"</p>
                </Trans>
            }
        />
    );
};
