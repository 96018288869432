import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";

const MetadataQuery = graphql`
    query Metadata {
        allMarkdownRemark(
            filter: { frontmatter: { type: { eq: "metadata" } } }
        ) {
            edges {
                node {
                    id
                    fields {
                        language
                    }
                    frontmatter {
                        title
                        description
                        og {
                            title
                            description
                            type
                            site_name
                        }
                        twitter {
                            site
                            card
                            title
                            description
                        }
                    }
                }
            }
        }
    }
`;

export const useMetadata = () => {
    const metadata = useStaticQuery<Queries.MetadataQuery>(MetadataQuery);
    const { language } = useI18next();
    const { edges } = metadata.allMarkdownRemark;
    const { frontmatter } = edges.find(
        ({ node }) => node.fields?.language === language
    )!.node;

    return frontmatter;
};
