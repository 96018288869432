import React, { useContext, useState, createContext } from "react";

const DMMPopupState = createContext<{
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}>({
    isOpen: true,
    setIsOpen: () => {},
});

export const useDMMPopupState = () => useContext(DMMPopupState);

export const DMMPopupContextProvider = (props: {
    children: React.ReactNode;
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <DMMPopupState.Provider value={{ isOpen, setIsOpen }}>
            {props.children}
        </DMMPopupState.Provider>
    );
};
