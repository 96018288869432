import React, { useContext, useState, createContext } from "react";

const SoundWarningContext = createContext<
    [ignore: boolean, setIgnore: React.Dispatch<React.SetStateAction<boolean>>]
>([false, () => {}]);

export const useSoundWarning = () => useContext(SoundWarningContext);

export const SoundWarningContextProvider = (props: {
    children: React.ReactNode;
}) => {
    const state = useState<boolean>(false);

    return <SoundWarningContext.Provider value={state} {...props} />;
};
