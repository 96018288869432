import React, { useContext, useState, createContext } from "react";

const WarningContext = createContext<{
    isOpen: boolean;
    onConfirm: () => void;
}>({
    isOpen: true,
    onConfirm: () => {},
});

export const useAgeWarningState = () => useContext(WarningContext);

export const WarningContextProvider = (props: {
    children: React.ReactNode;
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const handleConfirm = () => {
        setIsOpen(false);
    };

    return (
        <WarningContext.Provider value={{ isOpen, onConfirm: handleConfirm }}>
            {props.children}
        </WarningContext.Provider>
    );
};
